import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__mobile-alert" }
const _hoisted_3 = { class: "header__school-wrap" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "header__school-container" }
const _hoisted_6 = { class: "header__school-name" }
const _hoisted_7 = { class: "header__school-code" }
const _hoisted_8 = { class: "header__right-block" }
const _hoisted_9 = { class: "header__email" }
const _hoisted_10 = {
  key: 0,
  class: "header__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileAlert = _resolveComponent("MobileAlert")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Copy = _resolveComponent("Copy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MobileAlert),
      _cache[2] || (_cache[2] = _createTextVNode(" Please turn the screen over "))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, {
        to: "/admin/instructors",
        class: "header__logo"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo)
        ]),
        _: 1
      }),
      (_ctx.showSchoolBlock)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(this.school.name), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(this.school.code), 1),
                (this.school.code)
                  ? (_openBlock(), _createBlock(_component_Copy, {
                      key: 0,
                      onClick: _ctx.copyText
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.email), 1),
      (_ctx.role === 'super_admin' && !_ctx.isSuperAdminPage)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stopImpersonate && _ctx.stopImpersonate(...args))),
            class: "header__return-to-sa"
          }, " Return to SA "))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
        class: "header__logout"
      }, "Log out")
    ]),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Code copied!"))
      : _createCommentVNode("", true)
  ]))
}