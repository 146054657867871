import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/school-choice',
    name: 'SchoolChoice',
    component: () => import('../views/SchoolChoice.vue')
  },
  {
    path: '/admin/analytics/skills-tracking',
    name: 'SkillsTracking',
    component: () => import('../views/admin/analytics/SkillsTracking.vue')
  },
  {
    path: '/admin/analytics/number-of-next-level',
    name: 'NumberOfNextLevel',
    component: () => import('../views/admin/analytics/NumberOfNextLevel.vue')
  },
  {
    path: '/admin/analytics/average-time-student-level',
    name: 'AverageTimeStudentLevel',
    component: () => import('../views/admin/analytics/AverageTimeStudentLevel.vue')
  },
  {
    path: '/admin/analytics/students/fast-learners',
    name: 'FastLearners',
    component: () => import('../views/admin/analytics/students/FastLearners.vue')
  },
  {
    path: '/admin/analytics/students/slow-movers',
    name: 'SlowMovers',
    component: () => import('../views/admin/analytics/students/SlowMovers.vue')
  },
  {
    path: '/admin/analytics/students/students-moved-up-to-next-level',
    name: 'StudentsMovedUpToNextLevel',
    component: () => import('../views/admin/analytics/students/StudentsMovedUpToNextLevel.vue')
  },
  {
    path: '/admin/analytics/students/time-spent-in-program',
    name: 'TimeSpentInProgram',
    component: () => import('../views/admin/analytics/students/TimeSpentInProgram.vue')
  },
  {
    path: '/admin/instructors',
    name: 'Instructors',
    component: () => import('../views/admin/Instructors.vue')
  },
  {
    path: '/admin/billing-activity',
    name: 'BillingActivity',
    component: () => import('../views/admin/BillingActivity.vue')
  },
  {
    path: '/admin/students-history',
    name: 'StudentsHistory',
    component: () => import('../views/admin/StudentsHistory.vue')
  },
  {
    path: '/admin/instructor',
    name: 'Instructor',
    component: () => import('../views/admin/Instructor.vue')
  },
  {
    path: '/admin/create-instructors',
    name: 'CreateInstructor',
    component: () => import('../views/admin/CreateInstructor.vue')
  },
  {
    path: '/admin/students',
    name: 'Students',
    component: () => import('../views/admin/Students.vue')
  },
  {
    path: '/admin/student',
    name: 'Student',
    component: () => import('../views/admin/Student.vue')
  },
  {
    path: '/admin/edit-student',
    name: 'EditStydent',
    component: () => import('../views/admin/EditStydent.vue')
  },
  {
    path: '/admin/sticker',
    name: 'Sticker',
    component: () => import('../views/admin/Sticker.vue')
  },
  {
    path: '/admin/stickers',
    name: 'Stickers',
    component: () => import('../views/admin/Stickers.vue')
  },
  {
    path: '/admin/skill-achievement-log',
    name: 'SkillAchievementLog',
    component: () => import('../views/admin/SkillAchievementLog.vue')
  },
  {
    path: '/admin/create-sticker',
    name: 'CreateSticer',
    component: () => import('../views/admin/CreateSticer.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/privacy-policy.vue')
  },
  {
    path: '/delete',
    name: 'Delete',
    component: () => import('../views/delete-pages/delete.vue')
  },
  {
    path: '/delete-second-step',
    name: 'DeleteSecondStep',
    component: () => import('../views/delete-pages/delete-second-step.vue')
  },
  {
    path: '/delete-error',
    name: 'DeleteError',
    component: () => import('../views/delete-pages/delete-error.vue')
  },
  {
    path: '/delete-finish',
    name: 'DeleteFinish',
    component: () => import('../views/delete-pages/delete-finish.vue')
  },
  {
    path: '/admin/payments-log',
    name: 'PaymentsLog',
    component: () => import('../views/admin/PaymentsLog.vue')
  },
  {
    path: '/admin/settings',
    name: 'Settings',
    component: () => import('../views/admin/Settings.vue')
  },
  {
    path: '/super-admin/school-list',
    name: 'SchoolList',
    component: () => import('../views/super-admin/school-list.vue')
  },
  {
    path: '/super-admin/creare-school',
    name: 'CreateSchool',
    component: () => import('../views/super-admin/creare-school.vue')
  },
  {
    path: '/super-admin/school',
    name: 'School',
    component: () => import('../views/super-admin/school.vue')
  },
  {
    path: '/super-admin/admin',
    name: 'Admin',
    component: () => import('../views/super-admin/admin.vue')
  },
  {
    path: '/super-admin/school-admin-list',
    name: 'SchoolAdminList',
    component: () => import('../views/super-admin/school-admin-list.vue')
  },
  {
    path: '/super-admin/creare-school-admin',
    name: 'CreateSchoolAdmin',
    component: () => import('../views/super-admin/creare-school-admin.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/privacy-policy', '/delete', '/delete-second-step', '/delete-error', '/delete-finish']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('admin-sra')
  if (authRequired && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
