import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class InstructorService {
  getInstructor(id: string) {
    return axios
      .get(API_URL + `admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/instructors/${id}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getInstructors(params: string) {
    return axios
      .get(API_URL + `admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/instructors${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteInstructor(id: string) {
    return axios
      .delete(API_URL + `admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/instructors/${id}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getInstructorStudents(params: any) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/instructors/${params.id}/students${params.filters}${params.sort}${params.start_date}${params.end_date}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  generateInviteCode(data: any) {
    return axios
      .post(API_URL + 'admin/invite-codes', {
        instructors: data
      }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new InstructorService()
